import React from 'react';

import Layout from '../layouts/index';

export default function About() {
  return (
    <Layout>
      <div className="text-red-500">Hi</div>
    </Layout>
  );
}
