import Helmet from 'react-helmet';
import React from 'react';
import { siteTitle, siteDescription } from '../site-config';
import Link from 'gatsby-link';
import '../index.tailwind.css';

export default function Layout({ children }) {
  return (
    <div className="container m-auto">
      <Helmet title={siteTitle} meta={[{ name: 'description', content: siteDescription }]} />
      <nav className="mb-8">
        <Link to="/" className="mr-3">
          Home
        </Link>
        <Link to="/about">About</Link>
      </nav>
      {children}
    </div>
  );
}
